@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/components.css";
@import "./styles/typography.css";

@layer base {
  @font-face {
    font-family: "Manrope";
    src: url("../public/fonts/Manrope-ExtraLight.ttf");
    font-weight: 200;
  }
  @font-face {
    font-family: "Manrope";
    src: url("../public/fonts/Manrope-Light.ttf");
    font-weight: 300;
  }
  @font-face {
    font-family: "Manrope";
    src: url("../public/fonts/Manrope-Regular.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: "Manrope";
    src: url("../public/fonts/Manrope-Medium.ttf");
    font-weight: 500;
  }
  @font-face {
    font-family: "Manrope";
    src: url("../public/fonts/Manrope-SemiBold.ttf");
    font-weight: 600;
  }
  @font-face {
    font-family: "Manrope";
    src: url("../public/fonts/Manrope-Bold.ttf");
    font-weight: 700;
  }
  @font-face {
    font-family: "Manrope";
    src: url("../public/fonts/Manrope-ExtraBold.ttf");
    font-weight: 800;
  }

  html {
    font-family: "Manrope", "system-ui", "sans-serif";
    height: 100%;
  }

  body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  #root,
  main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .spinner {
    display: inline-block;
    position: relative;
  }

  .spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 8px;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  .spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

/* START: Zendesk Widget */
@media (max-width: 1023px) {
  .loggedIn #launcher {
    display: none;
  }

  .loggedIn #webWidget {
    display: none;
  }

  /* Hide zendesk hello message */
  .loggedIn div > div > div > div > iframe {
    display: none !important;
  }

  .loggedOut:not(.landing) div > div > div > div > iframe {
    display: none;
  }

  .loggedOut:not(.landing) #launcher {
    display: none;
  }

  .loggedOut:not(.landing) #webWidget {
    display: none;
  }
}

/* END: Zendesk Widget */

/* PDF PAGES */

.pdf-page {
  font-family: Arial, Helvetica, sans-serif;
}
.pdf-page h1 {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 6px;
  letter-spacing: -0.12px;
}

.pdf-page h2 {
  color: rgb(70, 70, 70);
  font-weight: bold;
  font-size: 22px;
  margin-top: 24px;
  margin-bottom: 6px;
  letter-spacing: -0.12px;
  line-height: 1.3;
}

.pdf-page p {
  line-height: 1.2;
  margin-bottom: 16px;
  letter-spacing: -0.12px;
}

.pdf-page ol,
.pdf-page ul {
  padding-left: 44px;
  margin-bottom: 18px;
  margin-top: 12px;
}
.pdf-page ol {
  list-style: decimal;
}
.pdf-page ul {
  list-style: disc;
}

.pdf-page li {
  margin-bottom: 2px;
  letter-spacing: -0.12px;
}

.pdf-page a {
  color: #003ade;
  text-decoration: underline;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input {
    @apply border text-left text-input transition-colors duration-300 placeholder:truncate placeholder:text-caption placeholder:font-semibold placeholder:text-black/30 focus:outline-none focus:ring-blue-600;
  }

  .card {
    @apply flex w-full flex-col rounded-3xl bg-white px-9 py-8;
  }

  .card-rounded-top {
    @apply flex h-full w-full flex-col rounded-t-3xl bg-white px-5 py-8 lg:h-auto lg:rounded-3xl lg:p-6;
  }
}

.h1-xl {
  @apply text-h1-xl-xl;
}

.h2 {
  @apply text-h2;
}

.h4 {
  @apply text-h4;
}

.h6 {
  /* remove font-normal when move to typography */
  @apply text-new-h6 font-normal;
}

.subtitle {
  @apply text-new-subtitle;
}

.caption {
  @apply text-new-caption;
}
